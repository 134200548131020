import Swiper from 'swiper/bundle';
import SimpleBar from 'simplebar';
import noUiSlider from 'nouislider';

$(document).ready(function() {
  $('.hamburger').click(function() {
    $(this).toggleClass('active');

    $('.offer__left').toggleClass('active');
  });
});